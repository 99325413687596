import { graphql, type HeadFC, type PageProps } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { type FC } from 'react';

import { FiizyShort } from '@/components/calculators/fiizy/fiizy-short';
import { ContentWithHeader } from '@/components/content/content-with-header';
import { ContentWithNavigation } from '@/components/content/content-with-navigation';
import { AppHead } from '@/components/head';
import { TableOfContents } from '@/components/navigations/table-of-contents';

interface ArticlePageProps {
    article: GraphqlSelect.Article<'name' | 'lead' | 'body' | 'metaTitle' | 'metaDescription' | 'image'>;
}

type ArticlePageContext = GraphqlSelect.Article<'id'>;

const ArticlePage: FC<PageProps<ArticlePageProps>> = ({ data: { article } }) => {
    return (
        <article>
            <ContentWithHeader
                style={{ marginBottom: 'clamp(18px, 5.6vw, 72px)' }}
                breadcrumbs={[{ name: article.name }]}
                image={article.image?.remoteFile && <GatsbyImage image={getImage(article.image.remoteFile)!} alt="" />}
            >
                <h1 className="fs-56">{article.name}</h1>

                {article.lead?.raw && (
                    <p
                        style={{ marginTop: 'clamp(10px, 2.5vw, 32px)' }}
                        className="fs-24"
                        dangerouslySetInnerHTML={{ __html: article.lead.raw }}
                    />
                )}
            </ContentWithHeader>

            <ContentWithNavigation
                className="container"
                left={article.body.html}
                right={
                    <>
                        <TableOfContents headers={article.body.headers} />
                        <FiizyShort />
                    </>
                }
            />
        </article>
    );
};

export const Head: HeadFC<ArticlePageProps, ArticlePageContext> = ({
    data: { article },
    pageContext: { id },
    location: { pathname },
}) => {
    return (
        <AppHead
            index
            title={article.metaTitle}
            description={article.metaDescription}
            canonical={pathname}
            ogImage={{ id, type: 'articles' }}
        />
    );
};

export const query = graphql`
    query ArticlePage($id: String!) {
        article: content(id: { eq: $id }) {
            name
            metaTitle
            metaDescription

            lead {
                raw
            }

            body {
                html
                headers
            }

            image {
                remoteFile {
                    childImageSharp {
                        gatsbyImageData(height: 600)
                    }
                }
            }
        }
    }
`;

export default ArticlePage;
