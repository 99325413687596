import { graphql, useStaticQuery } from 'gatsby';
import React, { type CSSProperties, type FC } from 'react';

import { FiizyButton } from '@/components/calculators/fiizy/elements/fiizy-button';
import { FiizyForm } from '@/components/calculators/fiizy/elements/fiizy-form';
import { FiizyInput } from '@/components/calculators/fiizy/elements/fiizy-input';
import { FiizyRangeInput } from '@/components/calculators/fiizy/elements/fiizy-range-input';

interface FiizyShortQuery {
    form: GraphqlSelect.FormSettings<'title' | 'firstPoint' | 'secondPoint'>;
}

interface FiizyShortProps {
    headerAs?: keyof JSX.IntrinsicElements;
    icon?: boolean;
}

const descriptionStyle: CSSProperties = {
    gap: '16px 12px',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
};

const descriptionItemStyle: CSSProperties = {
    gridTemplateColumns: 'max-content 1fr',
};

// FIXME: Poprawić labele w inputach
export const FiizyShort: FC<FiizyShortProps> = ({ icon, headerAs: HeaderAs = 'h2' }) => {
    const { form } = useStaticQuery<FiizyShortQuery>(graphql`
        query FiizyShort {
            form: formSettings {
                title

                firstPoint {
                    raw
                }

                secondPoint {
                    raw
                }
            }
        }
    `);

    return (
        <div id="fiizy-box" className="box bg-light-blue c-dark border-blue w-100">
            <HeaderAs className="fs-20 d-flex g-16 ai-center jc-center">
                <span style={{ maxWidth: '300px' }} className="text-center c-dark">
                    {form.title}
                </span>

                <img width="47" height="46" src="/assets/home/mouse.svg" alt="" />
            </HeaderAs>

            <FiizyForm className="d-grid g-13 mt-16">
                <FiizyInput />
                <FiizyRangeInput showInfo />

                <FiizyButton />
            </FiizyForm>

            <div style={descriptionStyle} className="fs-14 mt-16 d-flex flex-wrap jc-evenly">
                {icon ? (
                    <>
                        <div style={descriptionItemStyle} className="d-grid ai-center g-13">
                            <img width="36" height="40" src="/assets/home/document.svg" alt="" />
                            <p dangerouslySetInnerHTML={{ __html: form.firstPoint.raw }} />
                        </div>

                        <div style={descriptionItemStyle} className="d-grid ai-center g-13">
                            <img width="41" height="41" src="/assets/home/hand.svg" alt="" />
                            <p dangerouslySetInnerHTML={{ __html: form.secondPoint.raw }} />
                        </div>
                    </>
                ) : (
                    <>
                        <p dangerouslySetInnerHTML={{ __html: form.firstPoint.raw }} />
                        <p dangerouslySetInnerHTML={{ __html: form.secondPoint.raw }} />
                    </>
                )}
            </div>
        </div>
    );
};
